import gql from 'graphql-tag'

export default gql`
    mutation UpdateParticipant(
        $participant: ID!
        $faction: Faction
        $commandPoints: Int
        $commandPointsInitial: Int
    ) {
        updateParticipant(
            participant: $participant
            faction: $faction
            commandPoints: $commandPoints
            commandPointsInitial: $commandPointsInitial
        ) {
            id
            faction
            approved
            displayName
            commandPoints
            commandPointsInitial
            __typename
        }
    }
`
