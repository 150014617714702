import gql from 'graphql-tag'

export default gql`
    mutation UpdateObjective(
        $objective: ID!
        $achievedAt: Int
        $discardedAt: Int
        $victoryPoints: Int
    ) {
        updateObjective(
            objective: $objective
            achievedAt: $achievedAt
            discardedAt: $discardedAt
            victoryPoints: $victoryPoints
        ) {
            id
            number
            achievedAt
            discardedAt
            victoryPoints
            __typename
        }
    }
`
