export default {
    ADEPTA_SORORITAS: 'transparent',
    ADEPTUS_CUSTODES:
        'linear-gradient(30deg, transparent 25%, rgba(204, 153, 0, 0.75) 100%)',
    ADEPTUS_MECHANICUS:
        'linear-gradient(30deg, transparent 25%, rgba(162, 55, 23, 0.75) 100%)',
    ADEPTUS_MINISTORUM: 'transparent',
    AELDARI: 'transparent',
    ASTRA_MILITARUM: 'transparent',
    BLOOD_ANGELS: 'transparent',
    CHAOS_DAEMONS: 'transparent',
    CHAOS_KNIGHTS: 'transparent',
    CHAOS_SPACE_MARINES: 'transparent',
    DARK_ANGELS:
        'linear-gradient(30deg, transparent 25%, rgba(42, 74, 42, 0.75) 100%)',
    DEATH_GUARD: 'transparent',
    DEATH_WATCH: 'transparent',
    DRUKHARI: 'transparent',
    GENESTEALER_CULTS: 'transparent',
    GREY_KNIGHTS: 'transparent',
    HARLEQUINS: 'transparent',
    IMPERIAL_KNIGHTS: 'transparent',
    INQUISITION: 'transparent',
    NECRONS:
        'linear-gradient(30deg, transparent 25%, rgba(40, 176, 20, 0.80) 100%)',
    OFFICIO_ASSASSINORUM: 'transparent',
    ORKS: 'transparent',
    SISTERS_OF_BATTLE: 'transparent',
    SPACE_MARINES: 'transparent',
    SPACE_WOLVES: 'transparent',
    THOUSAND_SONS: 'transparent',
    TYRANIDS: 'transparent',
    TAU_EMPIRE: 'transparent',
}
