import gql from 'graphql-tag'

export default gql`
    mutation AddParticipant($game: ID!) {
        addParticipant(game: $game) {
            id
            __typename
        }
    }
`
