import React, { Fragment } from 'react'

import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import SpearheadAssault from './assets/spearhead_assault.svg'
import DawnOfWar from './assets/dawn_of_war.svg'
import SearchAndDestroy from './assets/search_and_destroy.svg'
import HammerAndAnvil from './assets/hammer_and_anvil.svg'
import FrontlineAssault from './assets/front-line_assault.svg'
import VanguardStrike from './assets/vanguard_strike.svg'

const DEPLOYMENT_MAPS = {
    'Spearhead Assault': SpearheadAssault,
    'Dawn of War': DawnOfWar,
    'Search and Destroy': SearchAndDestroy,
    'Hammer and Anvil': HammerAndAnvil,
    'Front-line Assault': FrontlineAssault,
    'Vanguard Strike': VanguardStrike,
    Custom: '',
}

export default props => (
    <Fragment>
        <Row>
            <Col className="mt-4">
                <h2>Select Deployment Map</h2>
                <p>Choose on of the deployment maps below</p>
            </Col>
        </Row>
        <Row>
            {Object.keys(DEPLOYMENT_MAPS).map(item => (
                <Col
                    key={item}
                    xs={12}
                    sm={6}
                    md={4}
                    xl={2}
                    onClick={() => {
                        props.updateGame({
                            variables: {
                                game: props.data.game.id,
                                deploymentMap: item,
                            },
                        })
                    }}
                    style={{ cursor: 'pointer' }}
                    className="text-center mb-4">
                    <Card
                        border={
                            props.data.game.deploymentMap === item
                                ? 'success'
                                : ''
                        }>
                        <Card.Img variant="top" src={DEPLOYMENT_MAPS[item]} />
                        <Card.Body>
                            <Card.Text className="text-center">
                                {item}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    </Fragment>
)
