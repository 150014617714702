import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

import React, { useEffect } from 'react'

import PubSub from 'pubsub-js'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'

import FACTION_DISPLAY_NAME from './enums/factionDisplayName'
import FACTION_DISPLAY_COLOR from './enums/factionDisplayColor'
import UPDATE_PARTICIPANT from './mutations/updateParticipant'

const QUERY = gql`
    query Participant($id: ID!) {
        __type(name: "Faction") {
            name
            enumValues {
                name
            }
        }
        participant(id: $id) {
            id
            faction
            approved
            displayName
            image
            commandPointsInitial
        }
    }
`

export default props => {
    const { loading, error, data, refetch } = useQuery(QUERY, {
        variables: { id: props.id },
    })
    const [updateParticipant] = useMutation(UPDATE_PARTICIPANT)

    useEffect(() => {
        const subscription = PubSub.subscribe(props.id, (msg, data) => {
            refetch()
        })
        return () => {
            PubSub.unsubscribe(subscription)
        }
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return (
        <Card>
            <Card.Body
                style={{
                    background:
                        FACTION_DISPLAY_COLOR[data.participant.faction] ||
                        'transparent',
                }}>
                <Card.Title className="d-flex align-items-center justify-content-between">
                    {data.participant.displayName}
                    <Image
                        className="ml-2"
                        src={data.participant.image}
                        roundedCircle
                        fluid
                        width={20}
                        height={20}
                        alt={data.participant.displayName}
                        title={data.participant.displayName}
                    />
                </Card.Title>

                <Form.Group>
                    <Form.Label>Command Points at start of game</Form.Label>
                    <div>
                        <Button
                            onClick={() => {
                                updateParticipant({
                                    variables: {
                                        participant: data.participant.id,
                                        commandPointsInitial:
                                            data.participant
                                                .commandPointsInitial - 1,
                                    },
                                })
                            }}>
                            -
                        </Button>
                        {data.participant.commandPointsInitial}
                        <Button
                            onClick={() => {
                                updateParticipant({
                                    variables: {
                                        participant: data.participant.id,
                                        commandPointsInitial:
                                            data.participant
                                                .commandPointsInitial + 1,
                                    },
                                })
                            }}>
                            +
                        </Button>
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Faction</Form.Label>
                    <Form.Control
                        as="select"
                        custom
                        value={data.participant.faction || ''}
                        onChange={e => {
                            updateParticipant({
                                variables: {
                                    participant: data.participant.id,
                                    faction: e.target.value,
                                },
                            })
                        }}>
                        <option disabled key=""></option>
                        {data.__type.enumValues.map(faction => (
                            <option key={faction.name} value={faction.name}>
                                {FACTION_DISPLAY_NAME[faction.name] ||
                                    faction.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Card.Body>
        </Card>
    )
}
