import React from 'react'
import QRCode from 'qrcode.react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export default props => (
    <Modal show={props.show} onHide={props.close}>
        <Modal.Header closeButton>
            <Modal.Title>Share Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col>
                        <h2>Send this link</h2>
                        <input value={window.location.href} readOnly />
                        {navigator.share && (
                            <Button
                                onClick={() => {
                                    navigator.share({
                                        title: 'Enter Game',
                                        url: window.location.href,
                                    })
                                }}>
                                Share
                            </Button>
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h2 className="mt-4">Scan this code</h2>
                        <QRCode value={window.location.href} />
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>
)
