import PubSub from 'pubsub-js'

import React, { Component } from 'react'

import { Router, navigate } from '@reach/router'

import TagManager from 'react-gtm-module'

import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloProvider } from '@apollo/react-hooks'

import './custom.scss'
import Container from 'react-bootstrap/Container'

import Dashboard from './Dashboard'
import Game from './Game'
import Spectator from './Spectator'
import Landing from './Landing'
import Navigation from './Navigation'
import socket from './socket'

require('typeface-staatliches')
require('typeface-source-sans-pro')

const tagManagerArgs = {
  gtmId: 'GTM-N6T4DWK',
}

TagManager.initialize(tagManagerArgs)

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

socket()

class App extends Component {
  state = { token: null }

  componentDidMount() {
    let token = null
    const urlParams = new URLSearchParams(window.location.search)

    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token')
    }

    if (urlParams.get('token')) {
      token = urlParams.get('token')
      localStorage.setItem('token', token)
    }

    if (token !== null) {
      PubSub.publish('SOCKET', { type: 'login', token })
    }

    this.setState({ token })
    navigate(window.location, { replace: true })
  }
  logout = () => {
    localStorage.removeItem('token')
    this.setState({ token: null })
    navigate('/')
  }
  render() {
    return (
      <ApolloProvider client={client}>
        <Navigation
          loggedIn={this.state.token ? true : false}
          logout={this.logout}
        />
        <Container fluid className="mt-3">
          {this.state.token && (
            <Router>
              <Dashboard path="/" />
              <Spectator path="/spectator" />
              <Game path="/games/:id" />
            </Router>
          )}

          {!this.state.token && (
            <Router>
              <Landing path="/" />
              <Spectator path="/spectator" />
            </Router>
          )}
        </Container>
      </ApolloProvider>
    )
  }
}

export default App
