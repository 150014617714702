export default {
    ADEPTA_SORORITAS: 'Adepta Sororitas',
    ADEPTUS_CUSTODES: 'Adeptus Custodes',
    ADEPTUS_MECHANICUS: 'Adeptus Mechanicus',
    ADEPTUS_MINISTORUM: 'Adeptus Ministorum',
    AELDARI: 'Aeldari',
    ASTRA_MILITARUM: 'Astra Militarum',
    BLOOD_ANGELS: 'Blood Angels',
    CHAOS_DAEMONS: 'Chaos Daemons',
    CHAOS_KNIGHTS: 'Chaos Knights',
    CHAOS_SPACE_MARINES: 'Chaos Space Marines',
    DARK_ANGELS: 'Dark Angels',
    DEATH_GUARD: 'Death Guard',
    DEATH_WATCH: 'Death Watch',
    DRUKHARI: 'Drukhari',
    GENESTEALER_CULTS: 'Genestealer Cults',
    GREY_KNIGHTS: 'Grey Knights',
    HARLEQUINS: 'Harlequins',
    IMPERIAL_KNIGHTS: 'Imperial Knights',
    INQUISITION: 'Inquisition',
    NECRONS: 'Necrons',
    OFFICIO_ASSASSINORUM: 'Officio Assassinorum',
    ORKS: 'Orks',
    SISTERS_OF_BATTLE: 'Sisters of Battle',
    SPACE_MARINES: 'Space Marines',
    SPACE_WOLVES: 'Space Wolves',
    THOUSAND_SONS: 'Thousand Sons',
    TYRANIDS: 'Tyranids',
    TAU_EMPIRE: 'Tau Empire',
}
