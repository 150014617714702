import React, { Fragment } from 'react'
import { navigate } from '@reach/router'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

export default props => {
    return (
        <Navbar className="navbar-dark bg-dark">
            <Navbar.Brand href="/">Tracker</Navbar.Brand>
            {props.loggedIn && (
                <Fragment>
                    <Nav className="mr-auto">
                        <Nav.Link
                            onClick={() => {
                                navigate('/')
                            }}>
                            Home
                        </Nav.Link>
                    </Nav>
                    <Form inline>
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            onClick={props.logout}>
                            Sign Out
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Navbar>
    )
}
