import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

import React, { Fragment, useEffect } from 'react'

import PubSub from 'pubsub-js'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import ToggleButton from 'react-bootstrap/ToggleButton'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'

import UPDATE_PARTICIPANT from './mutations/updateParticipant'
import CREATE_OBJECTIVE from './mutations/createObjective'
import UPDATE_OBJECTIVE from './mutations/updateObjective'

import FACTION_DISPLAY_NAME from './enums/factionDisplayName'

const QUERY = gql`
    query Participant($id: ID!) {
        __type(name: "Faction") {
            name
            enumValues {
                name
            }
        }
        participant(id: $id) {
            id
            image
            faction
            approved
            displayName
            commandPoints
            commandPointsInitial
            objectives {
                id
                number
                name
                achievedAt
                discardedAt
                victoryPoints
            }
        }
    }
`

export default props => {
    const { loading, error, data, refetch } = useQuery(QUERY, {
        variables: { id: props.id },
    })
    const [updateParticipant] = useMutation(UPDATE_PARTICIPANT)
    const [createObjective] = useMutation(CREATE_OBJECTIVE, {
        update() {
            refetch()
        },
    })
    const [updateObjective] = useMutation(UPDATE_OBJECTIVE, {
        update() {
            refetch()
        },
    })

    useEffect(() => {
        const subscription = PubSub.subscribe(props.id, (msg, data) => {
            refetch()
        })
        return () => {
            PubSub.unsubscribe(subscription)
        }
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return (
        <Card>
            <Card.Body>
                <Card.Title className="text-uppercase d-flex align-items-center justify-content-between">
                    {FACTION_DISPLAY_NAME[data.participant.faction]}
                    <Image
                        className="ml-2"
                        src={data.participant.image}
                        roundedCircle
                        fluid
                        width={20}
                        height={20}
                        alt={data.participant.displayName}
                        title={data.participant.displayName}
                    />
                </Card.Title>
                <Card.Text>
                    <Form.Group>
                        <Form.Label>Command Points</Form.Label>
                        <Button
                            onClick={() => {
                                updateParticipant({
                                    variables: {
                                        participant: data.participant.id,
                                        commandPoints:
                                            data.participant.commandPoints - 1,
                                    },
                                })
                            }}>
                            -
                        </Button>
                        {data.participant.commandPoints}/
                        {data.participant.commandPointsInitial}
                        <Button
                            onClick={() => {
                                updateParticipant({
                                    variables: {
                                        participant: data.participant.id,
                                        commandPoints:
                                            data.participant.commandPoints + 1,
                                    },
                                })
                            }}>
                            +
                        </Button>
                    </Form.Group>
                    <Button
                        onClick={() => {
                            createObjective({
                                variables: {
                                    participant: data.participant.id,
                                },
                            })
                        }}>
                        Create Objective
                    </Button>
                    {data.participant.objectives
                        .sort((a, b) => a.number > b.number)
                        .map(objective => (
                            <Fragment key={objective.id}>
                                {objective.number} - {objective.name}{' '}
                                {!objective.achievedAt &&
                                    !objective.discardedAt && (
                                        <Fragment>
                                            <Button
                                                onClick={() => {
                                                    updateObjective({
                                                        variables: {
                                                            objective:
                                                                objective.id,
                                                            achievedAt:
                                                                props.game.turn,
                                                        },
                                                    })
                                                }}>
                                                Achieved
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    updateObjective({
                                                        variables: {
                                                            objective:
                                                                objective.id,
                                                            discardedAt:
                                                                props.game.turn,
                                                        },
                                                    })
                                                }}>
                                                Discard
                                            </Button>
                                        </Fragment>
                                    )}
                                {objective.achievedAt && (
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="victoryPoints"
                                        value={objective.victoryPoints}
                                        onChange={val => {
                                            updateObjective({
                                                variables: {
                                                    objective: objective.id,
                                                    victoryPoints: val,
                                                },
                                            })
                                        }}>
                                        <ToggleButton value={1}>1</ToggleButton>
                                        <ToggleButton value={2}>2</ToggleButton>
                                        <ToggleButton value={3}>3</ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            </Fragment>
                        ))}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
