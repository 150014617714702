import PubSub from 'pubsub-js'

const socket = new WebSocket(process.env.REACT_APP_BACKEND_SOCKET_URL, [])

export default () => {
    PubSub.subscribe('SOCKET', (message, data) => {
        if (socket.readyState === 1) {
            socket.send(JSON.stringify(data))
        } else {
            setTimeout(() => {
                PubSub.publish('SOCKET', data)
            }, 1000)
        }
    })
    socket.addEventListener('message', e => {
        try {
            const data = JSON.parse(e.data)
            PubSub.publish(data.id, data)
        } catch {}
    })
}
