import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

import React from 'react'

import { DateTime } from 'luxon'
import { navigate } from '@reach/router'

import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'

import CREATE_GAME from './mutations/createGame'

const QUERY = gql`
    query {
        games {
            id
            createdAt
            scenario
            deploymentMap
            participants {
                displayName
                image
            }
        }
    }
`

const Game = props => (
    <Row
        onClick={() => {
            navigate(`/games/${props.data.id}`)
        }}
        style={{ cursor: 'pointer' }}>
        <Col className="d-flex align-items-center">
            {props.data.scenario || '-'} / {props.data.deploymentMap || '-'}
            {props.data.participants.map((participant, i) => (
                <Image
                    key={i}
                    className="mt-1 mb-1 ml-2"
                    style={{ marginRight: '-12px' }}
                    src={participant.image}
                    roundedCircle
                    fluid
                    width={30}
                    height={30}
                    alt={participant.displayName}
                    title={participant.displayName}
                />
            ))}
        </Col>
        <Col md="auto" className="d-flex align-items-center">
            <Badge variant="secondary">
                {DateTime.fromMillis(
                    parseInt(props.data.createdAt, 10)
                ).toLocaleString({
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                })}
            </Badge>
        </Col>
    </Row>
)

export default props => {
    const { loading, error, data, refetch } = useQuery(QUERY)
    const [createGame] = useMutation(CREATE_GAME, {
        update() {
            refetch()
        },
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    const gamesNow = data.games.filter(
        game => parseInt(game.createdAt, 10) > Date.now() - 1000 * 60 * 60 * 24
    )

    const gamesPast = data.games.filter(
        game => parseInt(game.createdAt, 10) <= Date.now() - 1000 * 60 * 60 * 24
    )
    return (
        <Row>
            <Col xs={12}>
                <h1>Games</h1>
                <p>
                    Face mop beefeater Freddie mercury graeme souness, groucho
                    marx graeme souness face mop john cleese iron tache
                    beefeater Freddie mercury. Lipwig marquess of queensbury
                    Fallen eyebrow bogie basket, tip top old bean marquess of
                    queensbury clive dunn alpha trion tudor philosopher Fallen
                    eyebrow bogie basket lipwig groucho-a-like face broom goose?
                </p>
                <Button
                    variant="primary"
                    onClick={async () => {
                        createGame().then(result => {
                            navigate(`/games/${result.data.createGame.id}`)
                        })
                    }}>
                    New Game
                </Button>
            </Col>
            <Col xs={12} lg={6}>
                <h2 className="mt-4">Running</h2>
                {gamesNow.map(game => (
                    <Game key={game.id} data={game} />
                ))}
            </Col>
            <Col xs={12} lg={6}>
                {gamesPast.length > 0 && <h2 className="mt-4">Past</h2>}
                {gamesPast.map(game => (
                    <Game key={game.id} data={game} />
                ))}
            </Col>
        </Row>
    )
}
