import React from 'react'

import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'

export default props => {
    const addPlayersFinished =
        props.data.game.participants.filter(
            participant => participant.faction !== null
        ).length > 1

    const selectScenarioFinished = props.data.game.scenario !== null
    const selectDeploymentMapFinished = props.data.game.deploymentMap !== null

    return (
        <ListGroup className="sticky-top pt-4">
            <ListGroup.Item variant={addPlayersFinished ? 'success' : ''}>
                Add Players
            </ListGroup.Item>
            <ListGroup.Item variant={selectScenarioFinished ? 'success' : ''}>
                Select Scenario
            </ListGroup.Item>
            <ListGroup.Item
                variant={selectDeploymentMapFinished ? 'success' : ''}>
                Select Deployment Map
            </ListGroup.Item>
            <ListGroup.Item
                onClick={() => {
                    props.updateGame({
                        variables: {
                            game: props.data.game.id,
                            stage: 'RUNNING',
                        },
                    })
                }}
                action
                disabled={
                    !addPlayersFinished ||
                    !selectScenarioFinished ||
                    !selectDeploymentMapFinished
                }
                className="d-flex align-items-center justify-content-between"
                active={props.data.game.stage === 'RUNNING'}>
                Run Game{' '}
                {addPlayersFinished &&
                    selectScenarioFinished &&
                    selectDeploymentMapFinished && (
                        <Spinner animation="grow" variant="success" size="sm" />
                    )}
            </ListGroup.Item>
        </ListGroup>
    )
}
