import React, { Fragment } from 'react'

import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

export default props => (
    <Fragment>
        <Row>
            <Col className="mt-4">
                <h2>Select Scenario</h2>
                <p>Choose on of these scenarios</p>
            </Col>
        </Row>
        <Row>
            <Col xs={12} lg={3} className="mb-4">
                <Form.Control
                    as="select"
                    custom
                    value={props.data.game.scenario || ''}
                    onChange={e => {
                        props.updateGame({
                            variables: {
                                game: props.data.game.id,
                                scenario: e.target.value,
                            },
                        })
                    }}>
                    <option></option>
                    <optgroup label="Eternal War">
                        <option>Retrieval Mission</option>
                        <option>No Mercy</option>
                        <option>The Scouring</option>
                        <option>Big Guns Never Tire</option>
                        <option>Secure and Control</option>
                        <option>The Relic</option>
                    </optgroup>

                    <optgroup label="Maelstrom of War">
                        <option>Cleanse and Capture</option>
                        <option>Contact Lost</option>
                        <option>Tactical Escalation</option>
                        <option>Spoils of War</option>
                        <option>Cloak and Shadows</option>
                        <option>Deadlock</option>
                    </optgroup>

                    <optgroup label="Chapter Approved Eternal War">
                        <option>Vital Intelligence</option>
                        <option>Narrow the Search</option>
                        <option>Cut Off the Head</option>
                        <option>The Four Pillars</option>
                        <option>Supplies from Above</option>
                        <option>Beachhead</option>
                    </optgroup>

                    <optgroup label="Chapter Approved Maelstrom of War">
                        <option>Disruptive Signals</option>
                        <option>Decapitation Strike</option>
                        <option>Strategic Gamble</option>
                        <option>Tactical Cascade</option>
                        <option>Visions of Victory</option>
                        <option>Scars of Battle</option>
                    </optgroup>
                </Form.Control>
            </Col>
        </Row>
    </Fragment>
)
