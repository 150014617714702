import React, { Fragment } from 'react'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'

import FACTION_DISPLAY_NAME from './enums/factionDisplayName'
// import FACTION_DISPLAY_COLOR from './enums/factionDisplayColor'

import Player from './PlayerInGame'

const PlayerOverview = props => (
    <Card>
        <Card.Body>
            <p className="text-center display-2">{props.data.victoryPoints}</p>
        </Card.Body>
        <Card.Footer className="text-uppercase d-flex align-items-center justify-content-between">
            {FACTION_DISPLAY_NAME[props.data.faction]}
            <Image
                className="ml-2"
                src={props.data.image}
                roundedCircle
                fluid
                width={20}
                height={20}
                alt={props.data.displayName}
                title={props.data.displayName}
            />
        </Card.Footer>
    </Card>
)

export default props => (
    <Fragment>
        <Row>
            {props.data.game.participants.slice(0, 1).map(p => (
                <Col xs={12} md={2} lg={3} xl={4} key={p.id} className="mt-4">
                    <PlayerOverview data={p} />
                </Col>
            ))}
            <Col className="text-center mt-4">
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h2>Current Turn</h2>
                        </Card.Title>
                        <p className="display-1">{props.data.game.turn}</p>
                        <ButtonGroup as="span">
                            <Button
                                onClick={() => {
                                    props.updateGame({
                                        variables: {
                                            game: props.data.game.id,
                                            turn: props.data.game.turn - 1,
                                        },
                                    })
                                }}>
                                -
                            </Button>

                            <Button
                                onClick={() => {
                                    props.updateGame({
                                        variables: {
                                            game: props.data.game.id,
                                            turn: 1 + props.data.game.turn,
                                        },
                                    })
                                }}>
                                +
                            </Button>
                        </ButtonGroup>
                    </Card.Body>
                </Card>
            </Col>
            {props.data.game.participants.slice(1).map(p => (
                <Col xs={12} md={2} lg={3} xl={4} key={p.id} className="mt-4">
                    <PlayerOverview data={p} />
                </Col>
            ))}
        </Row>

        <Row>
            <Col></Col>
        </Row>
        <Row>
            {props.data.game.participants.map(p => (
                <Col xs={12} className="mt-4">
                    <Player key={p.id} id={p.id} game={props.data.game} />
                </Col>
            ))}
        </Row>
    </Fragment>
)
