import { useMutation } from '@apollo/react-hooks'

import React, { Fragment } from 'react'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Player from './PlayerSetup'

import ADD_PARTICIPANT from './mutations/addParticipant'
import APPROVE_PARTICIPANT from './mutations/approveParticipant'

export default props => {
    const [addParticipant] = useMutation(ADD_PARTICIPANT, {
        update() {
            props.refetch()
        },
    })

    const [approveParticipant] = useMutation(APPROVE_PARTICIPANT, {
        update() {
            props.refetch()
        },
    })

    const waitingForapproval = props.data.game.participants.filter(
        p => !p.approved
    )

    return (
        <Fragment>
            <Row>
                <Col className="mt-4">
                    <h2>Players in Game</h2>
                    <p>
                        Invite other players by{' '}
                        <Button
                            onClick={() => {
                                props.setShowSharing(true)
                            }}
                            variant="link">
                            sharing this game
                        </Button>{' '}
                        or add another faction if you are managing this game on
                        your own.
                    </p>
                </Col>
            </Row>
            <Row>
                {props.data.game.participants
                    .filter(p => p.approved)
                    .map(p => (
                        <Col key={p.id} xs={12} md className="mb-4">
                            <Player id={p.id} />
                        </Col>
                    ))}
                <Col xs={12} xl={2} className="d-flex align-items-center mb-4">
                    <Button
                        className="mt-4 mb-4"
                        block
                        onClick={() => {
                            addParticipant({
                                variables: {
                                    game: props.data.game.id,
                                },
                            })
                        }}>
                        Add Faction
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>Players requesting to join</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    {waitingForapproval.length > 0 && (
                        <ul>
                            {waitingForapproval.map(p => (
                                <li key={p.id}>
                                    {p.displayName}{' '}
                                    <Button
                                        onClick={() => {
                                            approveParticipant({
                                                variables: {
                                                    participant: p.id,
                                                },
                                            })
                                        }}>
                                        Approve Participant
                                    </Button>
                                </li>
                            ))}
                        </ul>
                    )}
                    {waitingForapproval.length === 0 && (
                        <p>
                            No players are waiting to join this game. Share this
                            game to let other players join.
                        </p>
                    )}
                </Col>
                {waitingForapproval.length === 0 && (
                    <Col xs={12} xl={2} className="d-flex align-items-center">
                        <Button
                            block
                            onClick={() => {
                                props.setShowSharing(true)
                            }}>
                            Share Game
                        </Button>
                    </Col>
                )}
            </Row>
        </Fragment>
    )
}
