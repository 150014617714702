import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

import PubSub from 'pubsub-js'

import React, { Fragment, useEffect, useState } from 'react'

import { DateTime } from 'luxon'

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ShareGame from './ShareGame'
import StageAddPlayers from './StageAddPlayers'
import StageSelectScenario from './StageSelectScenario'
import StageSelectDeploymentMap from './StageSelectDeploymentMap'
import StageRunning from './StageRunning'
import StageSelect from './StageSelect'

import ADD_PARTICIPANT from './mutations/addParticipant'

const QUERY = gql`
    query Game($id: ID!) {
        game(id: $id) {
            id
            turn
            scenario
            deploymentMap
            stage
            createdAt
            spectatorCode
            participants {
                id
                image
                victoryPoints
                displayName
                faction
                approved
            }
        }
    }
`

const UPDATE_GAME = gql`
    mutation UpdateGame(
        $game: ID!
        $stage: Stage
        $scenario: String
        $deploymentMap: String
        $turn: Int
        $spectatorCode: String
    ) {
        updateGame(
            game: $game
            stage: $stage
            scenario: $scenario
            deploymentMap: $deploymentMap
            turn: $turn
            spectatorCode: $spectatorCode
        ) {
            id
            turn
            scenario
            deploymentMap
            stage
            spectatorCode
            __typename
        }
    }
`

export default props => {
    const [showSharing, setShowSharing] = useState(false)

    const { loading, error, data, refetch } = useQuery(QUERY, {
        variables: { id: props.id },
    })
    const [updateGame] = useMutation(UPDATE_GAME)

    const [addParticipant] = useMutation(ADD_PARTICIPANT, {
        update() {
            refetch()
        },
    })

    useEffect(() => {
        const subscription = PubSub.subscribe(props.id, (msg, data) => {
            refetch()
        })
        return () => {
            PubSub.unsubscribe(subscription)
        }
    })

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    const hasAccess = data.game.participants.length === 0 ? false : true

    let name = 'New Game'

    if (data.game.scenario) {
        name = `${data.game.scenario}`
    }
    if (data.game.scenario && data.game.deploymentMap) {
        name = `${data.game.scenario} - ${data.game.deploymentMap}`
    }

    return (
        <Fragment>
            <Row>
                <Col md="auto">
                    <h1>
                        {name}{' '}
                        <small className="text-muted">
                            {DateTime.fromMillis(
                                parseInt(data.game.createdAt, 10)
                            ).toLocaleString({
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: '2-digit',
                            })}
                        </small>
                    </h1>
                </Col>
                {hasAccess && (
                    <Col className="text-right">
                        <Button
                            onClick={() => {
                                setShowSharing(true)
                            }}>
                            Share
                        </Button>
                    </Col>
                )}
            </Row>

            {!hasAccess && (
                <Row>
                    <Col className="text-center">
                        <Button
                            size="lg"
                            className="mt-4"
                            onClick={() => {
                                addParticipant({
                                    variables: {
                                        game: data.game.id,
                                    },
                                })
                            }}>
                            Request Access
                        </Button>
                    </Col>
                </Row>
            )}

            {hasAccess &&
                [
                    'ADD_PLAYERS',
                    'SELECT_SCENARIO',
                    'SELECT_DEPLOYMENT_MAP',
                ].includes(data.game.stage) && (
                    <Row>
                        <Col xs={12}>
                            <p>
                                A in a ornare consectetur libero egestas dolor
                                odio vivamus ullamcorper consectetur penatibus
                                gravida fermentum. Bibendum habitant duis sed
                                fames ullamcorper consectetur sagittis eget
                                suspendisse accumsan quam condimentum vestibulum
                                lectus quis adipiscing aenean eleifend a
                                vestibulum habitasse himenaeos sem cum.
                                Vestibulum sociis felis varius ad vel sit a
                                suspendisse ac augue ante vestibulum nam
                                condimentum quam ut. Diam vivamus laoreet tempus
                                pretium quisque mattis feugiat non a vivamus eu
                                tristique convallis mi a pretium id aenean eros
                                condimentum mi augue ac ante pharetra duis.
                            </p>
                            <input
                                value={data.game.spectatorCode}
                                onChange={e => {
                                    updateGame({
                                        variables: {
                                            game: data.game.id,
                                            spectatorCode: e.target.value,
                                        },
                                    })
                                }}
                            />
                        </Col>
                        <Col xs={12} md={8} xl={10} className="mb-4 pt-4">
                            <StageAddPlayers
                                hasAccess={hasAccess}
                                data={data}
                                updateGame={updateGame}
                                refetch={refetch}
                                setShowSharing={setShowSharing}
                            />
                            <StageSelectScenario
                                data={data}
                                updateGame={updateGame}
                            />
                            <StageSelectDeploymentMap
                                data={data}
                                updateGame={updateGame}
                            />
                        </Col>
                        <Col xs={12} md={4} xl={2}>
                            <StageSelect updateGame={updateGame} data={data} />
                        </Col>
                    </Row>
                )}
            {hasAccess && data.game.stage === 'RUNNING' && (
                <StageRunning data={data} updateGame={updateGame} />
            )}
            <ShareGame
                show={showSharing}
                close={() => {
                    setShowSharing(false)
                }}
            />
        </Fragment>
    )
}
