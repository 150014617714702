import React from 'react'
import { navigate } from '@reach/router'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export default props => (
    <>
        <Row>
            <Col className="text-center">
                <Button
                    className="mt-4 mb-4 ml-4 mr-4"
                    href={`${process.env.REACT_APP_BACKEND_URL}/auth/google`}>
                    Sign In with Google
                </Button>

                <Button
                    className="mt-4 mb-4 ml-4 mr-4"
                    href={`${process.env.REACT_APP_BACKEND_URL}/auth/facebook`}>
                    Sign In with Facebook
                </Button>
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <Button className="mt-4" onClick={() => navigate('/spectator')}>
                    Cast Game to this device
                </Button>
            </Col>
        </Row>
    </>
)
