import gql from 'graphql-tag'

export default gql`
    mutation GetSpectatorCode {
        getSpectatorCode {
            id
            code
            __typename
        }
    }
`
