import gql from 'graphql-tag'

export default gql`
    mutation ApproveParticipant($participant: ID!) {
        approveParticipant(participant: $participant) {
            id
            faction
            approved
            displayName
            commandPointsInitial
            __typename
        }
    }
`
