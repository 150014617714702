import gql from 'graphql-tag'

export default gql`
    mutation CreateGame {
        createGame {
            id
            createdAt
            __typename
        }
    }
`
