import gql from 'graphql-tag'

export default gql`
    mutation CreateObjective($participant: ID!, $number: Int) {
        createObjective(participant: $participant, number: $number) {
            id
            number
            achievedAt
            __typename
        }
    }
`
