import gql from 'graphql-tag'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'

import GET_SPECTATOR_CODE from './mutations/getSpectatorCode'
import Carousel from 'react-bootstrap/Carousel'

const QUERY = gql`
    query Spectate($id: ID!) {
        spectate(id: $id) {
            id
        }
    }
`

export default props => {
    const [spectatorId, setSpectatorId] = useState(
        localStorage.getItem('spectatorId')
    )
    const [code, setCode] = useState(localStorage.getItem('spectatorCode'))

    const { loading, refetch } = useQuery(QUERY, {
        variables: { id: spectatorId },
        pollIntervall: 1000,
    })

    const [getSpectatorCode] = useMutation(GET_SPECTATOR_CODE, {
        async onCompleted(data) {
            await setCode(data.getSpectatorCode.code)
            await setSpectatorId(data.getSpectatorCode.id)
            localStorage.setItem('spectatorId', data.getSpectatorCode.id)
            localStorage.setItem('spectatorCode', data.getSpectatorCode.code)
            refetch()
        },
    })

    useEffect(() => {
        if (!spectatorId || !code) {
            getSpectatorCode()
        }
    }, [getSpectatorCode, spectatorId, code])

    if (loading) return 'Loading...'

    return (
        <div
            style={{
                position: 'absolute',
                background: 'black',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            }}>
            <Carousel
                controls={false}
                interval={10000}
                pause={false}
                slide={false}>
                <Carousel.Item>
                    <div
                        className="d-block"
                        style={{
                            height: '100vh',
                            background:
                                'url(https://picsum.photos/1280/768.jpg)',
                            backgroundSize: 'cover',
                        }}></div>
                </Carousel.Item>
                <Carousel.Item>
                    <div
                        className="d-block"
                        style={{
                            height: '100vh',
                            background:
                                'url(https://picsum.photos/1280/767.jpg)',
                            backgroundSize: 'cover',
                        }}></div>
                </Carousel.Item>
                <Carousel.Item>
                    <div
                        className="d-block"
                        style={{
                            height: '100vh',
                            background:
                                'url(https://picsum.photos/1280/769.jpg)',
                            backgroundSize: 'cover',
                        }}></div>
                </Carousel.Item>
            </Carousel>
            <div
                style={{
                    position: 'absolute',
                    bottom: '4rem',
                    left: '50%',
                    transform: 'translate(-50%)',
                    margin: '0 auto',
                    background: '#222',
                    padding: '1rem 2rem',
                }}>
                <p className="text-center display-3">{code}</p>
                <p className="text-center text-uppercase">Enter code to cast</p>
            </div>
        </div>
    )
}
